import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { CategoryContentQuery } from "../../graphql-types";
import { Intro } from "../components/Intro";
import { Layout } from "../components/Layout";
import { ProductCategoriesNav } from "../components/products/ProductCategoriesNav";
import { ProductCategoryGallery } from "../components/products/ProductCategoryGallery";
import { ProductCategorySuppliers } from "../components/products/ProductCategorySuppliers";
import { Section } from "../components/section/Section";
import { xlBreakpoint } from "../shared/constants";
import { useProductCategories } from "../shared/use-product-categories";

export const query = graphql`
  query CategoryContent($slug: String!) {
    datoCmsProductCategory(slug: { eq: $slug }) {
      title
      images {
        thumb: gatsbyImageData(width: 500, placeholder: BLURRED)
        full: gatsbyImageData(layout: FULL_WIDTH)
        filename
        title
      }
      suppliers {
        id
        name
        link
        logo {
          gatsbyImageData(
            layout: FIXED
            width: 200
            placeholder: BLURRED
            forceBlurhash: true
          )
        }
      }
    }
  }
`;

const ProductCategoryContainer = styled.div`
  @media (min-width: ${xlBreakpoint}px) {
    display: flex;

    & > div:first-child {
      flex: 1;
    }

    & > div:last-child {
      margin-left: 50px;
      flex: 0 200px;
    }
  }
`;

interface ProductPageProps {
  pageContext: {
    slug: string;
  };
  data: CategoryContentQuery;
}

const ProductPage: React.FC<ProductPageProps> = (props) => {
  const category = props.data.datoCmsProductCategory;
  const allCategories = useProductCategories();

  return (
    <Layout>
      <Helmet>
        <title>{`${category!.title!} | Prodotti`}</title>
      </Helmet>
      <Intro
        title="Prodotti"
        tagLine="Una selezione dei prodotti che vendiamo, con i collegamenti ai siti dei nostri fornitori"
      ></Intro>
      <Section>
        <ProductCategoriesNav
          basePath="prodotti"
          categories={allCategories}
        ></ProductCategoriesNav>
        <ProductCategoryContainer>
          <ProductCategoryGallery
            images={category!.images!}
          ></ProductCategoryGallery>
          <ProductCategorySuppliers
            suppliers={category!.suppliers!}
          ></ProductCategorySuppliers>
        </ProductCategoryContainer>
      </Section>
    </Layout>
  );
};

export default ProductPage;
