import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { ProductCategoryTitle } from "./ProductCategoryTitle";
import { ProductCategorySupplier } from "./types";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-auto-rows: 100px;
  grid-gap: 30px;
  justify-content: center;
`;

const SupplierLink = styled.a`
  display: block;
  height: 100%;
`;

interface ProductCategorySuppliersProps {
  suppliers: ProductCategorySupplier[];
}

export const ProductCategorySuppliers: React.FC<ProductCategorySuppliersProps> =
  (props) => (
    <div>
      <ProductCategoryTitle>Marchi</ProductCategoryTitle>
      <Grid>
        {props.suppliers.map((supplier) => (
          <SupplierLink
            key={supplier!.id}
            href={supplier!.link!}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GatsbyImage
              image={supplier!.logo!.gatsbyImageData}
              style={{ height: "100%" }}
              imgStyle={{ objectFit: "contain" }}
              alt={supplier!.name!}
            />
          </SupplierLink>
        ))}
      </Grid>
    </div>
  );
